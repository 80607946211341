<template>
    <div class="login-box quicklogin">
        <img :src="require('@/assets/gif/loading.gif')" alt="">
    </div>
</template>


<script>
import {mapMutations} from "vuex"
export default {
    methods:{
        ...mapMutations(['setdashboard','setCardList']),
    },
    mounted(){
        this.$ajax.upd2sdfgateUs345dfserIsdgnfo({
            hash:this.$route.query.auth
        }).then(res=>{
            if(res.code==200){
                localStorage.setItem("access_token",res.data.token);
                this.$router.push({name:"main-homeIndex"});
                this.$ajax.dashboard()
                .then(res=>{
                    this.setdashboard(res.data);
                    localStorage.setItem('dashboard',JSON.stringify(res.data))
                })
            }
        })
    }
}
</script>
